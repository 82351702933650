<template>
  <div class="home">
    <v-dialog
    v-model="dialog"
    :width="dialogWidth"
    
    fullscreen-mobile
  >
    <v-card>
     <v-btn
            icon
            @click="dialog = false"
            x-small
            fab
            class="close-button mt-1 mr-1"
          >
            <v-icon color="white " class="close-button-icon">mdi-close</v-icon>
          </v-btn>
      <v-img :src="imageSrc" ref="imageRef" @load="adjustDialogWidth" :contain="true" style="max-height: calc(100vh - 64px);" height="100%" ></v-img>
    </v-card>
  </v-dialog>
   
    <div class="hero">
      <commonSection />
    </div>
    <partnerCard />
    <sectionThree />
    <sectionFour />
    <sectionFive />
    <section id="videos">
      <div id="video-player-title">
        <h2>Watch Videos To know More About Us</h2>
      </div>
      <div id="video-Players">
        <iframe
          class="videoPlayer"
          frameBorder="0"
          src="https://www.youtube.com/embed/82mD3IeY3S0"
        >
        </iframe>

        <iframe
          class="videoPlayer"
          frameBorder="0"
          src="https://www.youtube.com/embed/JuVc1ttPreQ"
        >
        </iframe>
        <iframe
          class="videoPlayer"
          frameBorder="0"
          src="https://www.youtube.com/embed/samoqLotSX8"
        >
        </iframe>
      </div>
    </section>
    <HomeContactUs />
    <div class="floating-Action-Btn">
      <v-btn
        tile
        href="http://www.colombomall.lk"
        class="purple darken-2 white--text mall-btn"
      >
        Go to Mall
        <v-icon>mdi-cart</v-icon>
      </v-btn>
      <v-btn
        tile
        href="/colombomail"
        id="goto-mall"
        class="pink lighten-3 white--text mail-btn"
      >
        Go to Mail
        <v-icon>mdi-truck-fast</v-icon>
      </v-btn>
    </div>
    <div class="logo-container">
        <a href="https://www.vote.bestweb.lk/site/www_colombomail_lk">
            <img src="https://www.bw2024.lk/vote4us.png" alt="logo" width="150" height="150">
        </a>
    </div>

  </div>
</template>

<script>
import partnerCard from "../../components/main/home/partnerCard.vue";
import commonSection from "../../components/main/home/commanSection.vue";
import sectionThree from "../../components/main/home/sectionThree.vue";
import sectionFour from "../../components/main/home/sectionFour.vue";
import sectionFive from "../../components/main/home/sectionFive.vue";
import HomeContactUs from "../../components/home/contactUs.vue";

export default {
  name: "home",
  components: {
    partnerCard,
    commonSection,
    sectionThree,
    sectionFour,
    sectionFive,
    HomeContactUs,
  },
  created() {
    this.init();
  },
  data() {
    return {
     dialog: false,
      imageSrc: 'https://firebasestorage.googleapis.com/v0/b/colombo-mail.appspot.com/o/staticImages%2Fnewofficeaddres.jpg?alt=media&token=86c7a3c4-1c87-40eb-8f63-33449a8ae50a',
      dialogWidth: 800,
    };
  },
   mounted() {
    this.updateDialogWidth();
    window.addEventListener('resize', this.updateDialogWidth);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateDialogWidth);
  },
  methods: {
    init() {
      this.dialog = true;
    },

    adjustDialogWidth() {
      // Adjust dialog width based on the width of the loaded image
      const imageElement = this.$refs.imageRef.$el;
      if (imageElement) {
        this.dialogWidth = imageElement.offsetWidth;
        console.log("dialogWidth",this.dialogWidth)
      }
    },

    updateDialogWidth() {
      if (window.innerWidth < 600) {
        this.dialogWidth = 300;
      } else {
        this.dialogWidth = 500;
      }
    },
  },
};
</script>

<style scoped>
/* .hero {
  height: 92vh;
} */

.close-button {
  position: absolute;
  top: 0 ;
  right: 0 ;
  z-index: 2;
  background-color: red;
}

.logo-container {
    position: fixed; /* Fixed positioning so it stays in the viewport */
    bottom: 20px; /* Distance from the bottom of the viewport */
    right: 20px; /* Distance from the right of the viewport */
    z-index: 1000; /* Ensure it's above other content if necessary */
}

.img-pop {
  max-width: 510px;
  /* max-width: 80vw; */
}
.floating-Action-Btn {
  display: none;
}
.mall-btn,
.mail-btn {
  width: 50%;
  height: 50px;
}
#videos {
  background-color: rgb(252, 246, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 5vh 5vw;
}
#ideo-player-title {
  font-family: "Roboto", sans-serif;
  color: rgb(43, 0, 112);
  /* margin-bottom: 10vh; */
}
#video-Players {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 90vw;
  padding: 0px 10%;
  /* margin-top: 100vh; */
}
.videoPlayer {
  max-width: 300px;
  width: 30%;
  height: 165px;
  border-radius: 10px;
  margin-top: 20px;
}

@media only screen and (max-width: 600px) {
  #video-player-title {
    text-align: center;
  }
  #video-Players {
    flex-direction: column;
  }
  .videoPlayer {
    width: 100%;
  }
  .img-pop {
    /* width: auto; */
    max-width: 510px;
    height: auto;
  }

  .close-button {
  padding: 0px !important;
  margin: 0px !important;
  background: transparent !important;
}

.close-button-icon{
  color: red !important;
  background: transparent !important;
  padding-left: 10px !important;
  padding-top: 0px !important;
}

  /* .v-card-actions{
    width: 100%;
  } */
}
@media (max-width: 780px) {
  .floating-Action-Btn {
    position: sticky;
    left: 0;
    bottom: 0px;
    width: 100vw;
    display: flex;
    /* justify-content: space-evenly; */
  }
}
</style>
