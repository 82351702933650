import { db } from "../firebase";
export const calculatorService = {
    getCalculationData,
    getallExportServices,
};

async function getCalculationData() {
    console.log("getCalculationData");
    return await db
        .collection("pages")
        .doc("exportServiceData")
        .get()
        .then(response => {
            const data = response.data()

            console.log("response", data);
            return data;
        })
        .catch(error => {
            console.error("error from firebase", error);
            return error;
        });
}

async function getallExportServices() {
    let allExportServices = [{}, {}, {}, {}, {}, {}, {}, {}, {}]
    return await db
        .collection("exportServices")
        .get()
        .then((querySnapShot) => {
            querySnapShot.forEach((doc) => {
                let data = {};
                data = doc.data()
                data.id = doc.id
                if (data.id == "colomboExpress") allExportServices[0] = data
                if (data.id == "colomboEconomy") allExportServices[1] = data
                if (data.id == "singaporeSimple") allExportServices[2] = data
                if (data.id == "singaporeSmart") allExportServices[3] = data
                if (data.id == "swissFullyTracked") allExportServices[4] = data
                if (data.id == "swissCountryTrack") allExportServices[5] = data
                if (data.id == "hermies") allExportServices[6] = data
                if (data.id == "usps") allExportServices[7] = data
                if (data.id == "indiaPost") allExportServices[8] = data
            });
            console.log("allExportServices", allExportServices);
            return allExportServices
        })
        .catch((error) => {
            console.error("error", error);
            return error
        });
}



